import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { Queue } from '../../classes/queue/queue';
import { Request } from '../../interfaces/request';
import { ConnectionService } from '../connection/connection.service';
import { LogService } from '../log/log.service';
import { QueueService } from '../queue/queue.service';
import { Node } from '../../classes/queue/q-node';
import { ToastService } from '../toast/toast.service';
import { TranslatePipe } from '@ngx-translate/core';
import { COLORS } from 'src/constants/colors';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  private deviceIsNative: boolean;
  private logTag = 'requestService';

  private requestQueueId = 'requestQueue';
  private requestQueue: Queue;
  private onRequestQueueChange = new EventEmitter<void>();

  // Request toast settings
  private toastColor = COLORS.primary;
  private toastMessageDuration = 1500;
  private toastMessageDelay = 1000;

  private connectionWatcher: Subscription;
  private waitToFullyConnect = 1000;
  private somethingUploaded: boolean;

  constructor(
    private connectionService: ConnectionService,
    private platform: Platform,
    private queueService: QueueService,
    private http: HttpClient,
    private logService: LogService,
    private toastService: ToastService,
    private translatePipe: TranslatePipe
  ) {
    this.deviceIsNative = this.platform.is('cordova');
    this.init();
  }

  public async addRequest(request: Request): Promise<void> {
    // Insert in queue
    this.requestQueue.insert(new Node(request));
    if (this.deviceIsNative) {
      // Save queue to local when on native device
      await this.queueService.saveQueue(this.requestQueue);
    }
    this.triggerRequestQueue();
  }

  public triggerRequestQueue() {
    this.onRequestQueueChange.emit();
  }

  private init(): void {
    this.queueService.getQueue(this.requestQueueId).then((queue) => {
      this.requestQueue = queue;
      this.watchRequestQueue();
      this.triggerRequestQueue();
    });
  }

  private watchRequestQueue(): void {
    this.onRequestQueueChange.subscribe(async () => {
      // Check if device is online
      if (this.connectionService.deviceIsConnected()) {
        // Get first request from order
        const requestFromQueue = this.requestQueue.pop() as Request;
        // Check if not empty
        if (requestFromQueue) {
          // Make the request
          const request = this.doRequest(requestFromQueue).subscribe(
            () => {
              // Unsubscribe when result received
              request.unsubscribe();
              // Set uploaded flag for showing toast
              this.somethingUploaded = true;
              // Trigger this process again for next queued item.
              this.triggerRequestQueue();
              // Debugger logs
              this.logService.info(this.logTag, 'Request succeeded:');
              this.logService.object(requestFromQueue);
            },
            (err) => {
              // Log error and skip this request
              this.logService.error(this.logTag, 'Request failed:');
              this.logService.object(requestFromQueue);
              this.logService.error(this.logTag, 'Error:');
              this.logService.object(err);
              // Trigger queue for next queued item.
              this.triggerRequestQueue();
            }
          );
        } else {
          // Check if there was something uploaded
          if (this.somethingUploaded) {
            this.somethingUploaded = false;
            // Show success toast
            this.toastService.showToastMessage(
              this.translatePipe.transform('toast.requestQueueFinished'),
              this.toastColor,
              this.toastMessageDuration,
              this.toastMessageDelay
            );
          }

          if (this.deviceIsNative) {
            // Remove queue from local sql when on native device
            await this.queueService.deleteSavedQueue(this.requestQueueId);
          }
        }
      } else {
        // Check of connection is being watched
        if (!this.connectionWatcher) {
          // Watch connection and start queue when connected
          this.connectionWatcher = this.connectionService.watchConnection().subscribe(() => {
            setTimeout(() => {
              this.triggerRequestQueue();
            }, this.waitToFullyConnect);
          });
        }
      }
    });
  }

  private doRequest(request: Request): Observable<any> {
    switch (request.method) {
      case 'GET':
        return this.http.get(request.url);
      case 'PUT':
        return this.http.put(request.url, request.data);
      case 'POST':
        return this.http.post(request.url, request.data);
      case 'DELETE':
        return this.http.delete(request.url, request.data);
    }
  }
}
