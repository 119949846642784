import { Node } from './q-node';

export class Queue {
  constructor(public id: string, public head?: Node, public tail?: Node) {}

  public pop = (): any | void => {
    if (this.head) {
      const tempOut: Node | undefined = this.tail;
      this.tail = this.tail?.next;
      if (!tempOut?.value) {
        this.head = undefined;
      }
      return tempOut?.value;
    } else {
      return undefined;
    }
  };

  public insert = (node: Node): void => {
    if (this.head) {
      this.head.next = node;
      this.head = node;
    } else {
      this.head = node;
      this.tail = node;
    }
  };
}
